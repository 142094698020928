import { alpha, Grid, Typography } from '@material-ui/core'
import { Button } from '../button/button'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.typography.pxToRem(1199)};
  margin: auto;
  position: relative;
`

export const StyledImageBackground = styled.div`
  width: 100%;
  filter: drop-shadow(0 ${props => props.theme.typography.pxToRem(3)} ${props => props.theme.typography.pxToRem(15)} ${props => alpha(props.theme.palette.text.secondary, 0.19)});
  position: relative;
  padding: 0;
  z-index: 1;
  background: transparent;
`

export const StyledTrapezoid = styled.div`
  width: calc(100% - ${props => props.theme.typography.pxToRem(18)});
  height: ${props => props.theme.typography.pxToRem(210)};
  clip-path: polygon(0 0, 84% 0%, 100% 100%, 0% 100%);
  z-index: 10;
  background: ${props => props.theme.palette.common.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${props => props.theme.typography.pxToRem(32)} ${props => props.theme.typography.pxToRem(38)};

  ${props => props.theme.breakpoints.up('sm')} {
    clip-path: polygon(0 0, 89% 0%, 100% 100%, 0% 100%);
    width: calc(100% - ${props => props.theme.typography.pxToRem(40)});
    height: ${props => props.theme.typography.pxToRem(296)};
    flex-direction: row;
    align-items: center;
  }

  ${props => props.theme.breakpoints.up('md')} {
    width: calc(100% - ${props => props.theme.typography.pxToRem(39)});
    clip-path: polygon(0 0, 92% 0%, 100% 100%, 0% 100%);
    gap: 0 ${props => props.theme.typography.pxToRem(120)};
    padding: 0;
  }
`

export const StyledDoubleTriangle = styled.div`
  position: absolute;
  bottom: ${props => props.theme.typography.pxToRem(-6)};
  right: ${props => props.theme.typography.pxToRem(-6)};
`

export const StyledHorizontalHeadingSection = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${props => props.theme.breakpoints.up('sm')} {
    width: auto;
  }

  &::before {
    content: '';
    height: ${props => props.theme.typography.pxToRem(4)};
    width: ${props => props.theme.typography.pxToRem(30)};
    background-color: ${props => props.theme.palette.primary.main};
    margin-bottom: ${props => props.theme.typography.pxToRem(18)};

    ${props => props.theme.breakpoints.up('sm')} {
      height: ${props => props.theme.typography.pxToRem(5)};
      width: ${props => props.theme.typography.pxToRem(40)};
      margin-bottom: ${props => props.theme.typography.pxToRem(30)};
    }
  }
`

export const StyledVerticalHeadingSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(22)};
  font-weight: 500;
  margin-bottom: 20px;

  &::first-line {
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }

  &::before {
    content: '';
    float: right;
    width: 23%;
    height: ${props => props.theme.typography.pxToRem(1)};
  }

  ${props => props.theme.breakpoints.up(375)} {
    font-size: ${props => props.theme.typography.pxToRem(26)};

    &::before {
      width: ${props => props.theme.typography.pxToRem(150)};
    }

    &::first-line {
      font-size: ${props => props.theme.typography.pxToRem(22)};
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(40)};
    margin-bottom: 0;

    &::before {
      width: 37%;
    }

    &::first-line {
      font-size: ${props => props.theme.typography.pxToRem(30)};
      width: ${props => props.theme.typography.pxToRem(10)};
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    &::before {
      width: ${props => props.theme.typography.pxToRem(150)};
    }
  }
`

export const StyledButton = styled(Button)`
  font-size: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(18)};
    margin-top: 0;
  }
`

export const StyledButtonContainer = styled(Grid)`
  margin: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(75)};
  }

  > a {
    text-decoration: none;
  }
`